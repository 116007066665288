.App {
  height: 100%;
  text-align: center;
  background-color: #222831;
}

#header {
  height: 10%;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  background-color: #222831;
  color: #DDDDDD;
  font-size: 1.9em;
  font-weight: normal;
}

#container {
  height: 80%;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  background-color: #30475E;
  color: #DDDDDD;
}

#drum-machine {
  height: 80%;
  width: 50%;
  border-radius: 25px;
  border: 5px solid #DDDDDD;
  background-color: #222831;
}

#drum-machine-display {
  height: 100%;
  display: flex;
}

#drum-pads {
  width: 50%;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  padding: 9px;
}

.drum-pads-col {
  padding: 3px;
}

.drum-pad {
  padding: 20px 10px 20px 10px;
  background-color: #DDDDDD;
  border-radius: 10px;
  border: 1px solid #222831;
  color: #222831;
  font-weight: bolder;
  font-size: x-large;
  cursor: pointer;
}

.drum-pad button {
  color: #222831;
  font-size: 1.3em;
  /* font-weight: bolder; */
  font-family: 'Courier New', Courier, monospace;
  text-decoration: none;
}

.drum-pad button:hover {
  color: #222831;
}

#drum-controls {
  width: 50%;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}

#drum-controls .container-md .row {
  padding: 9px 0px 9px 0px;
}

input, label {
  display:block;
}

label {
  font-weight: bolder;
  font-variant: small-caps;
  font-size: larger;
}

#slider-simple-container {
  text-align: center;
}

.slider-simple {
  width: 100%;
  cursor: pointer;
}

#power-control {
  width: 20%;
  margin: auto;
}

#bank-selector-control {
  width: 20%;
  margin: auto;
}

#display {
  width: 100%;
  text-align: center;
  background-color: #DDDDDD;
  color: #222831;
  border: 0px;
}

#footer {
  height: 10%;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  background-color: #F05454;
  color: #222831;
}

#footer a {
  color: #DDDDDD;
  text-decoration: none;
}

#footer a:hover {
  font-weight: bolder;
}

#credits {
  font-size: smaller;
}
